import Bg from "./components/Bg";

function App() {
  return (
    <div className="App">
      <Bg />
    </div>
  );
}

export default App;
