import React, { useState } from 'react';
import video from '../assets/AyaseSaki.mp4';

function Bg() {
    const [hovered, setHovered] = useState(false);

    return (
        <div className="bgContainer">
            <div className="overlay"></div>
            <video src={video} autoPlay loop muted />
            <div className="container">
                <h1
                    className="title"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {hovered ? 'I Love Ayase Saki' : 'Website Under Construction'}
                </h1>
            </div>
        </div>
    );
}

export default Bg;
